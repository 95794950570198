<template>
    <SalesPage />
</template>

<script>
import {
    mapActions
} from 'vuex'

import SalesPage from '../components/SalesPage.vue'

export default {
    data: () => ({
    }),
    components: {
        SalesPage
    },
    methods: {
    },
    mounted() {
    }
}
</script>

