<template>
<v-container v-if="show" fluid fill-height>
    <v-card :class="`align-self-stretch ` + ($vuetify.breakpoint.mdAndUp ? `pa-4 ` : `pa-0` )" :flat="$vuetify.breakpoint.mdAndUp ? true : false" :elevation="$vuetify.breakpoint.mdAndUp ? `1` : `0`" width="100%">
        <v-card-title class="px-0">
            <h1>Історія замовлень</h1>
        </v-card-title>

        <v-alert max-width="700" type="error" outlined class="my-6 mx-auto" v-if="confirmedFalse">
            Немає доступу до інформації. Ваші контактні дані не підтверджені менеджером.
        </v-alert>
        <v-alert max-width="700" type="error" outlined class="my-6 mx-auto" v-if="contractorsFalse">
            Немає жодного доступного контрагента для перегляду iсторії замовлень.
        </v-alert>
        <template v-if="!confirmedFalse && !contractorsFalse">

            <v-card class="mb-4 blue lighten-5 px-0 px-md-2" flat>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" md="6" class="pb-0">
                            <h4>Дата замовлення:</h4>
                            <!-- <v-row>
                            <v-col cols="12" sm="6" lg="4" class="d-flex align-center">
                                <v-menu v-model="menu3" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="formatedOrderDateFrom" label="з" prepend-icon="event" readonly v-bind="attrs" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker color="primary" header-color="primary" locale="uk-ua" v-model="orderDateFrom" @input="menu3 = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" sm="6" lg="4" class="d-flex align-center">
                                <v-menu v-model="menu4" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="formatedOrderDateTo" label="по" prepend-icon="event" readonly v-bind="attrs" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker color="primary" header-color="primary" locale="uk-ua" v-model="orderDateTo" @input="menu4 = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row> -->
                            <!-- <h4>Продаж (видача):</h4> -->
                            <v-row>
                                <v-col cols="12" sm="6" lg="4" class="d-flex align-center">
                                    <v-menu v-model="menu1" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field v-model="formatedSellDateFrom" label="з" prepend-icon="event" readonly v-bind="attrs" v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker first-day-of-week="1" color="primary" header-color="primary" locale="uk-ua" v-model="sellDateFrom" @input="menu1 = false"></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="12" sm="6" lg="4" class="d-flex align-center">
                                    <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field v-model="formatedSellDateTo" label="по" prepend-icon="event" readonly v-bind="attrs" v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker first-day-of-week="1" color="primary" header-color="primary" locale="uk-ua" v-model="sellDateTo" @input="menu2 = false"></v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>
                            <h4 class="mt-4">Поточний статус обробки замовлення:</h4>
                            <div class="d-flex">
                                <v-checkbox hide-details class="mr-2" v-for="(key, value) in statuseS" v-model="statuS[value]" :label="statuseS[value].name" v-if="!statuseS[value].hidden" :key="value"></v-checkbox>
                            </div>
                            <!-- <h4>Контрагент:</h4>
                        <v-row align="center" class="pl-9">
                            <v-checkbox hide-details class="shrink mr-2 mt-0" v-for="item in contractors" v-model="contractor[item.typhoon_id]" :label="item.name" :key="item.id"></v-checkbox>
                        </v-row> -->
                        </v-col>
                        <v-col cols="12" md="6" class="pt-0">
                            <v-row>
                                <v-col cols="12" sm="6" md="12" lg="8" class="pt-0">
                                    <v-text-field v-model="documentNumber" label="Номер документа" prepend-icon="mdi-file-document" class="pt-6">
                                    </v-text-field>
                                    <v-text-field v-model="ttn" label="Номер декларації перевізника" prepend-icon="mdi-truck"></v-text-field>
                                </v-col>
                            </v-row>
                            <h4 class="mt-4">Форма оплати:</h4>
                            <div class="d-flex">
                                <v-checkbox hide-details class="mr-2" v-model="currency[14]" label="Безготівкова"></v-checkbox>
                                <v-checkbox hide-details class="mr-2" v-model="currency[4]" label="Інша форма"></v-checkbox>
                            </div>

                        </v-col>
                    </v-row>
                    <!-- <div class="my-5">
                    <h4>Поточний статус обробки документа:</h4>
                    <v-row align="center" class="pl-9">
                        <v-checkbox hide-details class="shrink mr-2 mt-0" v-for="(key, value) in statuses" v-model="status[value]" :label="statuses[value].name" v-if="!statuses[value].hidden" :key="value"></v-checkbox>
                    </v-row>
                </div> -->
                </v-card-text>

                <v-card-actions class="mt-4">
                    <v-btn class="primary" @click="loadList()">Показати</v-btn>
                    <v-btn @click="clear()">Очистити</v-btn>
                </v-card-actions>
            </v-card>
            <v-card class="d-flex align-center body-2 px-0 my-2" flat>
                <v-btn text :loading="xlsReportLoading" :disabled="totalItems > 0 ? false : true" @click="createGeneralXlsx()">
                    <span class="mr-2">
                        <v-icon color="green darken-3">{{exclelIcon}}</v-icon>
                    </span>
                    Завантажити
                </v-btn>
                <v-spacer></v-spacer>
                <div>
                    Знайдено замовлень: {{totalItems}}
                </div>
            </v-card>
            <v-data-table :search="search" @click:row="clicked" item-key="document" @update:expanded="exp" show-expand :expanded.sync="expanded" multi-sort :loading="listLoading" :headers="filteredHeadersSales" class="elevation-1 sales" :items="list" :footer-props="footerProps" :options.sync="options" mobile-breakpoint="0">
                <template v-slot:top>
                    <v-text-field clearable prepend-inner-icon="search" v-model="search" label="Шукати у знайденому" class="mx-4"></v-text-field>
                </template>
                <template v-slot:item.sell_date="{ item }">
                    {{item.sell_date ? toDate(item.sell_date) : '-'}}
                </template>
                <template v-slot:item.order_date="{ item }">
                    {{toDate(item.order_date)}}
                </template>
                <template v-slot:item.document="{ item }">
                    <span :class="$vuetify.breakpoint.mdAndUp ? `text-no-wrap` : ``">{{item.main_doc ? item.main_doc : ' - '}} {{item.transit_doc ? ` (Транзитний номер: ${item.transit_doc})`:``}}</span>
                </template>
                <template v-slot:item.status_ws="{ item }">
                    <v-chip :class="(statuses[item.status_ws].color || `grey`) + ` d-block white--text font-weight-bold`">{{statuses[item.status_ws].name}}</v-chip>
                </template>
                <template v-slot:item.sum="{ item }">
                    {{item.sum ? format(item.sum) : '-'}}
                </template>
                <template v-slot:item.data-table-expand="{ item, expand, isExpanded }">
                    <v-btn small :class="`ma-0 ml-n2 `+ (statuses[item.status_ws].color || `grey`)" dark icon>
                        <v-icon>{{isExpanded ? 'mdi-chevron-up':'mdi-chevron-down'}}</v-icon>
                    </v-btn>
                </template>
                <template v-slot:item.delivery="{ item }">
                    <div v-if="item.city">
                        {{`${item.city}` + (item.warehouse ? `, ${item.warehouse}`:``) + (item.street ?  `, ${item.street}`:'') + (item.house ? `, ${item.house}` : '') + (item.flat ? `, ${item.flat}` : '')}}
                    </div>
                </template>
                <template v-slot:no-data>
                    Немає данних
                </template>
                <template v-slot:loading>
                    Зачекайте...
                </template>
                <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length" class="pa-0">
                        <div class="text-center grey--text py-4" v-if="!expObj[item.document]">Зачекайте...</div>
                        <v-container fluid v-if="expObj[item.document]">
                            <v-row no-gutters>
                                <v-col cols="12" md="3" lg="4">
                                    <v-card tale flat>
                                        <v-card-title class="pl-1">
                                            Інформація по документу
                                        </v-card-title>
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="4" md="12" class="pa-0">
                                                <v-list dense>
                                                    <v-list-item v-if="expObj[item.document].documentData['status_ws']" class="d-flex d-md-none px-1">
                                                        <v-list-item-content>
                                                            <v-list-item-title>
                                                                <v-chip :class="(statuses[expObj[item.document].documentData['status_ws']].color || `grey`) + ` white--text font-weight-bold`">{{statuses[expObj[item.document].documentData['status_ws']].name}}</v-chip>
                                                                <!-- {{statuses[expObj[item.document].documentData['status_ws']].name}} -->
                                                            </v-list-item-title>
                                                            <v-list-item-subtitle>Статус обробки</v-list-item-subtitle>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                    <v-list-item v-if="expObj[item.document].documentData['contractorName']" class="d-flex d-md-none px-1">
                                                        <v-list-item-content>
                                                            <v-list-item-title>{{expObj[item.document].documentData['contractorName']}}</v-list-item-title>
                                                            <v-list-item-subtitle>Контрагент</v-list-item-subtitle>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                    <v-list-item class="px-1">
                                                        <v-list-item-content>
                                                            <v-list-item-title>{{expObj[item.document].deliveryInfo.currency_id == 14 ? 'Безготівкова' : 'Інша форма'}}</v-list-item-title>
                                                            <v-list-item-subtitle>Форма оплати</v-list-item-subtitle>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                    <v-list-item v-if="expObj[item.document].documentData['buyer']" class="px-1">
                                                        <v-list-item-content>
                                                            <v-list-item-title>{{expObj[item.document].documentData['buyer']}}</v-list-item-title>
                                                            <v-list-item-subtitle>Покупець</v-list-item-subtitle>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                    <v-list-item v-if="expObj[item.document].documentData['rn']" class="px-1">
                                                        <v-list-item-content>
                                                            <v-list-item-title>{{expObj[item.document].documentData['rn']}}</v-list-item-title>
                                                            <v-list-item-subtitle>РН</v-list-item-subtitle>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </v-list>
                                            </v-col>
                                            <v-col cols="12" sm="8" md="12" class="pa-0">
                                                <v-list dense v-if="expObj[item.document].deliveryInfo">
                                                    <h4 class="pl-1 mb-6">Інформація про доставку</h4>
                                                    <v-row>
                                                        <v-col cols="12" sm="6" md="12" class="pa-0">
                                                            <v-list-item v-if="shipping[expObj[item.document].deliveryInfo.shipment || expObj[item.document].deliveryInfo.shipment]">
                                                                <v-list-item-content>
                                                                    <v-list-item-title>{{shipping[expObj[item.document].deliveryInfo.shipment || expObj[item.document].deliveryInfo.shipment]}}</v-list-item-title>
                                                                    <v-list-item-subtitle>Вид доставки</v-list-item-subtitle>
                                                                </v-list-item-content>
                                                            </v-list-item>
                                                            <v-list-item v-if="expObj[item.document].deliveryInfo.carrier">
                                                                <v-list-item-content>
                                                                    <v-list-item-title>{{expObj[item.document].deliveryInfo.carrier}}</v-list-item-title>
                                                                    <v-list-item-subtitle>Перевізник</v-list-item-subtitle>
                                                                </v-list-item-content>
                                                            </v-list-item>
                                                            <v-list-item v-if="expObj[item.document].deliveryInfo.ext_req">
                                                                <v-list-item-content>
                                                                    <v-list-item-title>{{expObj[item.document].deliveryInfo.ext_req}}
                                                                        <span v-if="expObj[item.document].deliveryInfo.check_delivery_state"><a :href="`${expObj[item.document].deliveryInfo.check_url}${expObj[item.document].deliveryInfo.ext_req}`" target="_blank"><i>&nbsp;Перевірити стан доставки</i></a></span></v-list-item-title>
                                                                    <v-list-item-subtitle>Декларація</v-list-item-subtitle>
                                                                </v-list-item-content>
                                                            </v-list-item>
                                                            <v-list-item v-if="expObj[item.document].deliveryInfo.destination_type && expObj[item.document].deliveryInfo.shipment != `drop_shipping`">
                                                                <v-list-item-content>
                                                                    <!-- <v-list-item-title>{{expObj[item.document].deliveryInfo.destination_type}}{{expObj[item.document].deliveryInfo.warehouse ? `: ${expObj[item.document].deliveryInfo.warehouse}`:``}}</v-list-item-title> -->
                                                                    <v-list-item-title>{{expObj[item.document].deliveryInfo.destination_type}}</v-list-item-title>
                                                                    <v-list-item-subtitle>Спосіб доставки</v-list-item-subtitle>
                                                                </v-list-item-content>
                                                            </v-list-item>
                                                            <v-list-item v-if="expObj[item.document].deliveryInfo.city">
                                                                <v-list-item-content>
                                                                    <v-list-item-title>{{`${expObj[item.document].deliveryInfo.city}` + (expObj[item.document].deliveryInfo.warehouse ? `, ${expObj[item.document].deliveryInfo.warehouse}`:``) + (expObj[item.document].deliveryInfo.street ?  `, ${expObj[item.document].deliveryInfo.street}`:'') + (expObj[item.document].deliveryInfo.house ? `, ${expObj[item.document].deliveryInfo.house}` : '') + (expObj[item.document].deliveryInfo.flat ? `, ${expObj[item.document].deliveryInfo.flat}` : '')}}</v-list-item-title>
                                                                    <v-list-item-subtitle>Адреса</v-list-item-subtitle>
                                                                </v-list-item-content>
                                                            </v-list-item>
                                                        </v-col>
                                                        <v-col cols="12" sm="6" md="12" class="pa-0">
                                                            <v-list-item v-if="expObj[item.document].deliveryInfo.recipient_name">
                                                                <v-list-item-content>
                                                                    <v-list-item-title>{{expObj[item.document].deliveryInfo.recipient_name}}</v-list-item-title>
                                                                    <v-list-item-subtitle>Контактна особа</v-list-item-subtitle>
                                                                </v-list-item-content>
                                                            </v-list-item>
                                                            <v-list-item v-if="expObj[item.document].deliveryInfo.recipient_name">
                                                                <v-list-item-content>
                                                                    <v-list-item-title>{{expObj[item.document].deliveryInfo.recipient_phone}}</v-list-item-title>
                                                                    <v-list-item-subtitle>Контактний телефон</v-list-item-subtitle>
                                                                </v-list-item-content>
                                                            </v-list-item>
                                                        </v-col>
                                                    </v-row>
                                                </v-list>
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                </v-col>
                                <v-col cols="12" md="9" lg="8">
                                    <v-card tale flat>
                                        <v-card-title class="px-0">Товарна специфікація</v-card-title>
                                        <v-data-table hide-default-footer mobile-breakpoint="0" :headers="filteredHeadersProducts" class="products" dense :items="expObj[item.document].goodsList" :items-per-page="10000">
                                            <template v-slot:item.sn="{ item }">
                                                <div v-if="item.sn && item.sn.split(',').length == 1">{{item.sn}}</div>
                                                <div @click="openSn(item.sn)" style="cursor: pointer" class="link--text text-decoration-underline" v-if="item.sn && item.sn.split(',').length > 1">Показати SN ({{item.sn && item.sn.split(',').length}})</div>
                                            </template>
                                            <template v-slot:item.model="{ item }">
                                                <v-menu max-width="200" offset-y open-on-hover>
                                                    <template v-slot:activator="{ on }">
                                                        <span v-on="on" style="cursor: pointer">{{item.name_ukr}}</span>
                                                    </template>
                                                    <v-img height="200" width="200" :src="item.img"></v-img>
                                                </v-menu>
                                            </template>
                                            <!-- <template v-slot:item.price_doc="{ item }">
                                                {{!expObj[item.doc_name].documentData.sum && !expObj[item.doc_name].documentData.service_doc ? '-' : item.price_doc}}
                                            </template> -->
                                            <template v-slot:item.sum_doc="{ item }">
                                                {{expObj[item.doc_name].documentData.sum ? format(item.sum_doc) : '-'}}
                                            </template>
                                            <template v-slot:item.price_doc="{ item }">

                                                {{item.price_doc && item.price_doc != '-' ? (expObj[item.doc_name].documentData['sum'] ? format(item.price_doc) : '-' ): '-'}}
                                            </template>
                                            <template v-slot:item.rrc="{ item }">
                                                {{item.rrc == '-' ? item.rrc : format(item.rrc)}}
                                            </template>
                                        </v-data-table>
                                        <v-simple-table dense class="products sum">
                                            <template v-slot:default>
                                                <tbody>
                                                    <tr>
                                                        <td class="px-1 px-sm-4">Загальна кількість</td>
                                                        <td class="px-1 px-sm-4 text-right">{{expObj[item.document].documentData['total_qty']}} шт.</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="px-1 px-sm-4">Загальна сума</td>
                                                        <td class="px-1 px-sm-4 text-right">{{format(expObj[item.document].documentData['sum'])}} ₴</td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                        </v-simple-table>

                                        <v-btn text @click="createXlsx(item.document)" :loading="xlsLoading" class="px-2 px-sm-4 mt-8 mb-4">
                                            <span class="mr-2">
                                                <v-icon color="green darken-3">{{exclelIcon}}</v-icon>
                                            </span>
                                            <span>Завантажити Рахунок</span>
                                        </v-btn>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-container>
                        <!-- {{expObj[item.document] && expObj[item.document]}} -->
                    </td>
                </template>
            </v-data-table>
            <AlertCompanyBlock />
        </template>
    </v-card>
    <v-dialog v-model="dialog" max-width="450">
        <v-card>
            <v-card-title class="headline">
                Перелік серійних номерів товару
            </v-card-title>

            <v-card-text class="pl-12">
                <div v-for="n in sn.split(',')">{{n}}</div>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="dialog = false">
                    Закрити
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

</v-container>
</template>

<script>
import {
    mapActions,
    mapGetters

} from 'vuex'
import {
    connect
} from "../ReportSocket"

import md5 from "md5"

import {
    mdiMicrosoftExcel
} from '@mdi/js'
import AlertCompanyBlock from "./AlertCompanyBlock.vue"
export default {
    components: {
        AlertCompanyBlock
    },
    props: {},
    data() {
        return {
            exclelIcon: mdiMicrosoftExcel,
            show: false,
            confirmedFalse: false,
            contractorsFalse: false,
            menu1: false,
            menu2: false,
            menu3: false,
            menu4: false,
            search: '',
            xlsLoading: false,
            xlsReportLoading: false,
            closed: true,
            sellDateTo: null,
            sellDateFrom: null,
            orderDateTo: null,
            orderDateFrom: null,
            documentNumber: null,
            ttn: null,
            expanded: [],
            expObj: {},
            status: {},
            statuS: {},
            contractor: {},
            page: 1,
            pageCount: 0,
            totalPagesPagination: 7,
            actionId: '',
            goods: '',
            dialog: false,
            sn: '',
            currency: {},
            options: {
                page: 1,
                itemsPerPage: 40
            },
            footerProps: {
                'items-per-page-options': [20, 40, 80, 100],
                'items-per-page-text': 'на стор.'
            },
            mime: {
                xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
                xml: "application/xml"
            },
            headers: [{
                    text: 'Дата продажу',
                    align: 'center',
                    value: 'sell_date',
                    sortable: true,
                    width: '15%',
                },
                {
                    text: 'Дата замовлення',
                    align: 'center',
                    value: 'order_date',
                    sortable: true,
                    width: '15%',
                },
                {
                    text: 'Документ',
                    align: 'center',
                    value: 'document',
                    sortable: true,
                    width: '15%',
                },
                {
                    text: 'Доставка',
                    align: 'left',
                    value: 'delivery',
                    sortable: true,
                    width: '30%',
                    hiddenXs: true,
                    hiddenSm: true
                },
                {
                    text: 'Статус',
                    align: 'center',
                    value: 'status_ws',
                    sortable: true,
                    width: '15%',
                    hiddenXs: true
                },
                {
                    text: 'Контрагент',
                    align: 'center',
                    value: 'contractorName',
                    sortable: true,
                    width: '20%',
                    hiddenXs: true,
                    hiddenSm: true
                },
                {
                    text: 'Номер декларації перевізника',
                    align: 'center',
                    value: 'ttn',
                    sortable: true,
                    width: '20%',
                    hidden: true,
                },
                {
                    text: 'Сума, ₴',
                    align: 'right',
                    value: 'sum',
                    sortable: true,
                    width: '10%',
                },
                {
                    text: 'РН',
                    align: 'right',
                    value: 'rn',
                    sortable: true,
                    width: '20%',
                    hidden: true,
                },
                {
                    text: 'Покупець',
                    align: 'right',
                    value: 'buyer',
                    sortable: true,
                    width: '20%',
                    hidden: true,
                },
            ],
            goodsHeaders: [{
                    text: 'Код',
                    align: 'center',
                    value: 'typhoon_id',
                    sortable: false,
                    width: '5%',
                    hiddenXs: true
                },
                {
                    text: 'Артикул',
                    align: 'center',
                    value: 'artikul',
                    sortable: false,
                    width: '13%',
                    hiddenXs: true
                },
                {
                    text: 'Бренд',
                    align: 'center',
                    value: 'brand_name',
                    sortable: false,
                    width: '13%',
                },
                {
                    text: 'Товар',
                    align: 'left',
                    value: 'model',
                    sortable: false,
                    width: '37%',
                },
                {
                    text: 'SN',
                    align: 'center',
                    value: 'sn',
                    sortable: false,
                    width: '20%',
                    hiddenXs: true,
                },
                {
                    text: 'К-ть',
                    align: 'center',
                    value: 'quantity',
                    sortable: false,
                    width: '3%',
                    hiddenXs: true,
                },
                {
                    text: 'Ціна, ₴',
                    align: 'right',
                    value: 'price_doc',
                    sortable: false,
                    width: '5%',
                },
                {
                    text: 'РРЦ, ₴',
                    align: 'right',
                    value: 'rrc',
                    sortable: false,
                    width: '5%',
                    hiddenXs: true
                },
                {
                    text: 'Сума, ₴',
                    align: 'right',
                    value: 'sum_doc',
                    sortable: false,
                    width: '5%',
                },

            ],
            statuses: {
                'inwork': {
                    name: 'В обробці',
                    color: 'amber lighten-1',
                },
                'assembling': {
                    name: 'Сформований (на складі)',
                    color: 'light-blue lighten-3',
                },
                'assembled': {
                    name: 'Сформований (зібраний)',
                    color: 'light-blue lighten-1',

                },
                'region_assembled': {
                    name: 'Сформований (зібраний у регіоні)',
                    color: 'light-blue',
                },
                'region_delivery': {
                    name: 'У доставці (у регіоні)',
                    color: 'blue darken-3',
                },
                'output': {
                    name: 'Виданий',
                    color: 'light-green',
                    hidden: true
                },
                'region_transfer': {
                    name: 'Переміщується у регіон',
                    color: 'blue darken-3',
                },
                'delivery': {
                    name: 'У доставці',
                    color: 'blue darken-3',
                },
                'canceled': {
                    name: 'Скасований',
                    color: 'red darken-1',
                },
                'closed': {
                    name: 'Завершений',
                    color: 'green',
                },
                'undefined': {
                    name: '-',
                    color: '',
                },
            },
            statuseS: {
                'inwork': {
                    name: 'В обробці',
                    color: 'amber',
                },
                'completed': {
                    name: 'Сформований',
                    color: 'light-blue darken-2',
                },
                'delivery': {
                    name: 'У доставці',
                    color: 'light-blue darken-2',
                },
                'closed': {
                    name: 'Завершений',
                    color: 'green',
                },
                'canceled': {
                    name: 'Скасований',
                    color: 'red darken-1',
                }
            },
            shipping: {
                own: "Самовивіз",
                YC: "Доставка ЮК",
                route: "Доставка перевізником",
                YC_Kiev: "Доставка ЮК",
                drop_shipping: "Drop-shipping"
            }
        }
    },
    methods: {
        ...mapActions(['salesList', 'touch', 'getContactInfo', 'getClientContractors', 'getDocumentData']),
        loadList() {
            this.options.page = 1
            this.touch()
                .then(() => {
                    const q = this.$route.query
                    if (q.doc_id) {
                        this.documentNumber = q.doc_id
                    }
                    this.salesList({
                        sellDateTo: this.sellDateTo,
                        sellDateFrom: this.sellDateFrom,
                        orderDateTo: this.orderDateTo,
                        orderDateFrom: this.orderDateFrom,
                        documentNumber: this.documentNumber,
                        contractorId: Object.keys(this.contractor).filter(el => this.contractor[el] === true).join(','),
                        status: Object.keys(this.status).filter(el => this.status[el] === true).join(','),
                        statuS: Object.keys(this.statuS).filter(el => this.statuS[el] === true).join(','),
                        ttn: this.ttn,
                        currency: Object.keys(this.currency).filter(el => this.currency[el] === true).join(','),
                    })
                })
        },
        roundTo(v, n = 2) {
            return parseFloat(v).toLocaleString('ua-UA')
            // return parseFloat(v).toLocaleString('ua-UA', {
            //     style: 'currency',
            //     currency: 'UAH'
            // })
        },
        format(v) {
            if (v) {
                if (isNaN(v)) {
                    return '-'
                } else {
                    return Number(String(v).replace(/\s+/g, '')).toLocaleString('ru-RU')
                }
            } else {
                return 0
            }
        },
        toDate(date) {
            if (!date) return null
            const [year, month, day] = date.split('-')
            return `${day}-${month}-${year}`
        },
        setContractors() {
            for (let c of this.contractors) {
                this.$set(this.contractor, c.typhoon_id, true)
            }
        },
        clear() {
            this.sellDateTo = null
            this.sellDateFrom = null
            this.orderDateTo = null
            this.orderDateFrom = null
            this.documentNumber = null
            this.status = {}
            this.statuS = {}
            this.$store.commit('setList', [])
            this.$store.commit('setTotal', 0)
            this.search = ''
        },
        exp(value) {
            if (value && value[0]) {

                this.getDocumentData({
                        documentNumber: value[0].document,
                        documentTransit: value[0].transit_doc,
                        contractorId: value[0].contractor_id
                    })
                    .then(data => this.$set(this.expObj, value[0].document, data))
            }
        },
        openSn(sn) {
            this.dialog = true
            this.sn = sn
        },
        clicked(value) {
            const exp = this.expanded[0]
            this.expanded = []
            if (value.document !== (exp ? exp.document : 0)) {
                this.expanded.push(value)
            }
        },
        async connectSocket() {
            try {

                const {
                    se,
                    socket
                } = await connect()
                this.loaded = false
                this.xlsLoading = false
                this.xlsReportLoading = false
                this.se = se
                this.socket = socket

                this.se.$on("message", (msg) => {
                    const date = new Date().toISOString()
                    const filename = `invoice.xlsx`
                    this.fileName = filename
                    this.saveData(msg, filename);
                    this.loaded = false
                    this.xlsLoading = false
                    this.xlsReportLoading = false
                })
                this.se.$on("closed", () => {
                    setTimeout(() => {
                        this.closed = true
                        console.log("Websocket closed")
                    }, 5000);
                })
                this.se.$on("error", (error) => {
                    console.log("Не вдалося встановити з'єднання з сервером")
                    //this.setSnackbar(['error', "Не вдалося встановити з'єднання з сервером"])
                })
                this.se.$on("opened", () => {
                    this.closed = false
                    this.xlsLoading = false
                    this.xlsReportLoading = false
                })
            } catch (error) {
                console.error(error)
                this.xlsLoading = false
                this.xlsReportLoading = false
            }
        },
        async createGeneralXlsx() {
            if (this.closed) {
                try {
                    this.xlsLoading = true
                    await this.connectSocket()
                    this.closed = false
                } catch (error) {
                    console.error(error)
                    this.xlsLoading = false
                }
            }
            if (this.socket.readyState == 1) {
                window.URL.revokeObjectURL(this.fileBlobURL); //очистить память от ранее принятого файла
                this.fileBlobURL = ''
                this.loaded = true
                this.xlsReportLoading = true
                // const params = (new URL(document.location)).searchParams
                const token = this.getLocalStorage('authToken')
                this.se.send(JSON.stringify({
                    fileName: this.paramsHash + '_general_invoice.xlsx',
                    authToken: token,
                    type: 'sales-report',
                    contractorId: Object.keys(this.contractor).filter(el => this.contractor[el] === true).join(','),
                    sellDateTo: this.sellDateTo,
                    sellDateFrom: this.sellDateFrom,
                    orderDateTo: this.orderDateTo,
                    orderDateFrom: this.orderDateFrom,
                    documentNumber: this.documentNumber,
                    contractorId: Object.keys(this.contractor).filter(el => this.contractor[el] === true).join(','),
                    status: Object.keys(this.status).filter(el => this.status[el] === true).join(','),
                    statuS: Object.keys(this.statuS).filter(el => this.statuS[el] === true).join(','),
                    ttn: this.ttn
                }))
            } else {
                this.xlsLoading = false
                this.xlsReportLoading = false
                this.setSnackbar(['error', "Не вдалося встановити з'єднання з сервером WS"])
            }
        },
        async createXlsx(id) {
            if (this.closed) {
                try {
                    this.xlsLoading = true
                    await this.connectSocket()
                    this.closed = false
                } catch (error) {
                    console.error(error)
                    this.xlsLoading = false
                }
            }
            if (this.socket.readyState == 1) {
                window.URL.revokeObjectURL(this.fileBlobURL); //очистить память от ранее принятого файла
                this.fileBlobURL = ''
                this.loaded = true
                this.xlsLoading = true
                // const params = (new URL(document.location)).searchParams
                const token = this.getLocalStorage('authToken')
                this.se.send(JSON.stringify({
                    fileName: id + '_invoice.xlsx',
                    authToken: token,
                    type: 'invoice',
                    document: id,
                    contractorId: Object.keys(this.contractor).filter(el => this.contractor[el] === true).join(','),
                }))
            } else {
                this.xlsLoading = false
                this.xlsReportLoading = false
                this.setSnackbar(['error', "Не вдалося встановити з'єднання з сервером WS"])
            }
        },
        saveData(data, fileName, format) {
            const a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            const blob = new Blob([data], {
                type: this.mime[this.format]
            })
            let url = window.URL.createObjectURL(blob);
            this.fileBlobURL = a.href = url;
            a.download = fileName;
            a.click();
        },

        getLocalStorage(f) {
            let value = ''
            try {
                value = localStorage[f] ? JSON.parse(localStorage[f]) : ''
            } catch (error) {
                console.log(error)
                return ''
            }
            return value
        },

    },
    watch: {},
    created() {
        this.clear()
        this.getContactInfo()
            .then(data => {
                if (data.groups && data.groups.cl_sales && !data.isSellToEmployee) {
                    this.show = true
                    if (data.isConfirmed) {
                        this.getClientContractors()
                            .then(d => {
                                if (!d[0]) {
                                    this.contractorsFalse = true
                                } else {
                                    this.setContractors()
                                    const q = this.$route.query
                                    if (q.doc_id) {
                                        this.loadList()
                                    }
                                }
                            })
                    } else {
                        this.confirmedFalse = true
                    }

                } else {
                    this.$router.push('/403')
                }
            })
            .catch(error => console.log(error))
    },
    computed: {
        list() {
            return this.$store.state.sales.list
        },
        paramsHash() {
            const status = Object.keys(this.statuS).filter(el => this.statuS[el] === true).join(',')
            return md5(this.sellDateTo + this.sellDateFrom + this.ttn + status + this.documentNumber)
        },
        totalItems() {
            return this.$store.state.sales.total
        },
        listLoading() {
            return this.$store.state.sales.loading
        },
        contractors() {
            return this.$store.state.sales.contractors
        },
        formatedSellDateFrom() {
            return this.toDate(this.sellDateFrom)
        },
        formatedSellDateTo() {
            return this.toDate(this.sellDateTo)
        },
        formatedOrderDateFrom() {
            return this.toDate(this.orderDateFrom)
        },
        formatedOrderDateTo() {
            return this.toDate(this.orderDateTo)
        },
        documentData() {
            return this.$store.state.sales.documentData
        },
        filteredHeadersSales() {
            return this.headers.filter(h => {
                return !h.hidden ? (this.$vuetify.breakpoint.xs ? !h.hiddenXs : (this.$vuetify.breakpoint.smAndDown ? !h.hiddenSm : true)) : false
            })
        },
        filteredHeadersProducts() {
            return this.goodsHeaders.filter(h => {
                return !h.hidden ? (this.$vuetify.breakpoint.xs ? !h.hiddenXs : (this.$vuetify.breakpoint.smAndDown ? !h.hiddenSm : true)) : false
            })
        },
    },
}
</script>

<style>
.v-list-item__title {
    word-break: normal;
    /* maybe !important  */
    white-space: normal;
}

.v-data-table.products tbody td {
    background: #E3F2FD !important;
    font-size: 0.75rem !important;
    font-weight: 400;
    letter-spacing: 0.0333333333em !important;
    line-height: 1.25rem;
    font-family: "Roboto", sans-serif !important;
    font-weight: 300 !important;
}

.v-data-table.products.sum tbody td {
    background: #dde7ef !important;
    font-weight: 400 !important;
}

@media (max-width: 760px) {

    .v-data-table.sales>.v-data-table__wrapper th,
    .v-data-table.sales>.v-data-table__wrapper td {
        padding: 0 5px 0 0px;
    }

    .v-data-table.sales>.v-data-table__wrapper th:nth-child(1),
    .v-data-table.sales>.v-data-table__wrapper td:nth-child(1) {
        padding-left: 10px;
        padding-right: 0;
    }
}

@media (max-width: 512px) {

    .v-data-table.sales>.v-data-table__wrapper th,
    .v-data-table.sales>.v-data-table__wrapper tr:not(.v-data-table__expanded__content) td {
        font-size: 12px;
    }

    .v-data-table.sales>.v-data-table__wrapper td {
        padding-left: 5px;
    }

}
</style>
